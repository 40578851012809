<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('courses.description') }}</span>
				</v-card-title>
				<v-card-text>
					<p class="card-text text-justify" style="white-space: pre-line">
						{{ company.info.description }}
					</p>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CompanyDescription',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			company: 'companies/company',
		}),
	},
}
</script>
